<template>
  <div class="loading" v-if="loading">
    <div class="loading-text">
      <span class="loading-text-words">L</span>
      <span class="loading-text-words">O</span>
      <span class="loading-text-words">A</span>
      <span class="loading-text-words">D</span>
      <span class="loading-text-words">I</span>
      <span class="loading-text-words">N</span>
      <span class="loading-text-words">G</span>
    </div>
  </div>

  <div class="main index">
    <nav class="top-menu">
      <a href="">Greetings</a>
      <a href="">Painting</a>
      <a href="">Objects</a>
      <a href="">Photos</a>
      <a href="">Contacts</a>
    </nav>

    <div class="start">
      <div class="text-left">
        <div class="big-text">MARIA</div>
        <div class="little-text">
          ARTISTE
          <br>
          PEINTRE
        </div>
      </div>
      <img src="/marie.png" alt="Marie as main picture">
      <div class="text-right">
        <div class="big-text">TENN</div>
        <div class="little-text">
          PHOTO
          <br>
          CREATIF
        </div>
      </div>
    </div>

    <div class="welcome">
      WELCOME TO MY WORLD
    </div>

    <div class="bio">
      <div class="bio-text">
        Among the many masterpieces of the past, auction houses, prestigious galleries, and contemporary art... I invite
        you to slow down a bit and listen to yourself.
        <br>
        <br>
        Do you like it or not? Do I want to start my day glancing at this work? Can I relax in the evening in my cozy
        home? Does this painting bring me joy? Does the riot of colors remind me of my true temperament and passionate
        desires!? Does this work harmonize with natural materials and gold leaf? Does the moonlight and silver cool me
        off?
      </div>
      <img src="/bio-1.jpg" alt="Biography image">
    </div>

    <div class="bio bio-2">
      <img src="/bio-2.jpg" alt="Biography image">
      <div class="bio-text">
        What do I really want?
        As an artist, I love working with a specific person and their favorite interior, creating a custom canvas
        perfect in size, color combinations, and entirely woven from the secret and explicit desires of the future
        owner. I tremble with excitement for paired works (diptychs) that harmonize large spaces, bringing symmetry with
        the taste of each work's uniqueness.
        <br>
        <br>
        Let's forget about the rules of big collectors, famous museums, fashion houses, influential art critics. Let's
        create together this cozy and beautiful world, this delight for the eyes and peace for the soul. Let there be no
        rules. Only your personal perspective. Let there be only desire. A strong desire to enjoy life and savor every
        moment.
        I hope we are on the same path!
      </div>
    </div>

    <h1 class="footer">See you soon. Maria Tenn</h1>
    <div class="contacts">
      <a href="tel:+330689551051" target="_blank">
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M9.0066 15.4937C16.2424 22.7294 18.3627 20.9979 19.3096 20.3214C19.4627 20.2345 22.9675 17.9761 20.7878 15.7969C15.7323 10.741 16.7581 17.3126 11.9719 12.5274C7.18675 7.74113 13.759 8.76768 8.70346 3.71227C6.52353 1.53237 4.26491 5.03745 4.179 5.18952C3.50162 6.1365 1.7708 8.25801 9.0066 15.4937Z"
              stroke="white" stroke-width="1.25" stroke-linecap="round"/>
        </svg>
        <span>
          +33(0)689551051
        </span>
      </a>
      <a href="mailto:maria_tenn@sent.com" target="_blank">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
                stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M22 6L12 13L2 6" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span>
          maria_tenn@sent.com
        </span>
      </a>
      <a href="https://instagram.com/tennmarie" target="_blank">
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M17.5 2H7.5C4.73858 2 2.5 4.23858 2.5 7V17C2.5 19.7614 4.73858 22 7.5 22H17.5C20.2614 22 22.5 19.7614 22.5 17V7C22.5 4.23858 20.2614 2 17.5 2Z"
              stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path
              d="M16.5002 11.3701C16.6236 12.2023 16.4815 13.0523 16.094 13.7991C15.7065 14.5459 15.0933 15.1515 14.3418 15.5297C13.5903 15.908 12.7386 16.0397 11.908 15.906C11.0773 15.7723 10.31 15.3801 9.71503 14.7852C9.12011 14.1903 8.72793 13.4229 8.59426 12.5923C8.4606 11.7616 8.59226 10.91 8.97052 10.1584C9.34878 9.40691 9.95438 8.7938 10.7012 8.4063C11.448 8.0188 12.2979 7.87665 13.1302 8.00006C13.9791 8.12594 14.765 8.52152 15.3719 9.12836C15.9787 9.73521 16.3743 10.5211 16.5002 11.3701Z"
              stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M18 6.5H18.01" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span>
          @tennmarie
        </span>
      </a>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
    };
  },
  created() {

  },
  beforeMount() {
    setTimeout(() => {
      this.loading = false;
    }, 2000)

  },
  methods: {},
};
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap');
@import url(https://fonts.googleapis.com/css?family=Quattrocento+Sans);


@font-face {
  font-family: Zekton; /* Гарнитура шрифта */
  src: url('/zekton_font.ttf'); /* Путь к файлу со шрифтом */
}


html body {
  margin: 0;
  padding: 0;
  background-color: #0A0A0A;

  .main.index {
    max-width: 1240px;
    margin: 32px auto;

    .top-menu {
      display: flex;
      justify-content: center;
      gap: 64px;

      a, a:visited {
        color: #fff;
        text-decoration: none;
        font-weight: 600;
      }
    }

    .start {
      margin-top: 80px;
      display: flex;
      justify-content: center;
      align-items: center;

      .text-left, .text-right {
        .big-text {
          color: #fff;
          font-size: 100px;
          font-family: Zekton;
        }

        .little-text {
          color: #fff;
          font-size: 24px;
          font-family: Zekton;
        }
      }

      .text-left {
        margin-right: 90px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      .text-right {
        margin-left: 90px;
        display: flex;
        flex-direction: column;
      }
    }

    .welcome {
      margin-top: 60px;
      width: 100%;
      height: 100px;
      background: #303132;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 24px;
      font-family: Zekton;
    }

    .bio {
      margin-top: 80px;
      display: flex;
      gap: 80px;
      align-items: center;

      .bio-text {
        color: #fff;
        font-size: 24px;
        line-height: 160%;
        letter-spacing: 1px;
        font-family: "Raleway", sans-serif;
      }
    }

    h1.footer {
      margin: 100px auto 0 auto;
      display: flex;
      justify-content: center;
      color: #fff;
      font-size: 50px;
      font-family: "Raleway", sans-serif;
    }

    .contacts {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;

      a, a:visited {
        color: #fff;
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 8px;

        span {
          font-size: 24px;
        }
      }
    }
  }
}

/* loading */

@mixin position-center($text-align: center) {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: $text-align;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 9999;
}

.loading-text {
  @include position-center;
  width: 100%;
  height: 100px;
  line-height: 100px;

  span {
    display: inline-block;
    margin: 0 5px;
    color: #fff;
    font-family: 'Quattrocento Sans', sans-serif;
    @for $i from 0 through 6 {
      &:nth-child(#{$i + 1}) {
        filter: blur(0px);
        animation: blur-text 1.5s (#{$i/5})+s infinite linear alternate;
      }
    }
  }
}

@keyframes blur-text {
  0% {
    filter: blur(0px);
  }
  100% {
    filter: blur(4px);
  }
}


@media screen and (max-width: 768px) {
  html body {
    .main.index {
      margin-top: 0;
      max-width: 100%;
      width: 100%;
      overflow: hidden;

      .top-menu {
        display: none;
      }

      .start {
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        width: 100%;
        max-width: 100%;

        .text-left {

          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
          gap: 12px;
          margin-right: 0;
          margin-bottom: 24px;

          .big-text {
            font-size: 70px;
          }
        }

        img {
          width: 200px;
        }
        .text-right {
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-direction: row-reverse;
          gap: 12px;
          margin-left: 0;

          .big-text {
            font-size: 70px;
          }
        }
      }

      .bio {
        flex-direction: column;
        padding: 0 16px;

        .bio-text {
          font-size: 20px;
        }

        img {
          max-width: 100%;
        }

        &.bio-2 {
          display: flex;
          flex-direction: column-reverse;
        }
      }

      h1.footer {
        font-size: 24px;
        padding: 0 16px;
      }

      .contacts {
        padding: 0 16px;

        a {
          span {
            font-size: 20px;
          }
        }
      }
    }
  }
}
</style>
